export const add_category = {
  transmit: "single",
  url: "category_add",
  request: {
    name: "",
    description: "",
  },
};

export const edit_category = {
  transmit: "single",
  url: "category_update",
  request: {
    category_id: "",
    name: "",
    description: "",
    is_active: "",
  },
};

export const delete_category = {
  transmit: "single",
  url: "category_delete",
  request: {
    category_id: "",
  },
};

export const category_lists = {
  transmit: "single",
  url: "category_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const feedback_lists = {
  transmit: "single",
  url: "feedback_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const subscription_email_lists = {
  transmit: "single",
  url: "mailsubscription_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const setting_update = {
  transmit: "single",
  url: "setting_update",
  request: {
    data: {
      address: "",
      contact: {
        email: "",
        phone: "",
      },
      social: {
        facebook: "",
        instagram: "",
        twitter: "",
        linkedin: "",
      },
    },
  },
};


export const get_setting = {
  transmit: "single",
  url: "setting_get",
};
export const get_order_date_setting= {
  transmit: "single",
  url: "setting_order_date_validation"
}

export const delete_feedback = {
  transmit: "single",
  url: "feedback_delete",
  request: {
    feedback_id: "",
  },
};

export const retail_lists = {
  transmit: "single",
  url: "store_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const variant_lists = {
  transmit: "single",
  url: "variant_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const variant_product_lists = {
  transmit: "single",
  url: "variant_listing",
  request: {
    filter: "all",
    limit: 5000,
    page: 1,
    search: "",
  },
};

export const add_variant = {
  transmit: "single",
  url: "variant_add",
  request: {
    name: "",
    quantity: 0,
    product_limit: 0,
    price: 0,
  },
};

export const edit_variant = {
  transmit: "single",
  url: "variant_update",
  request: {
    variant_id: "",
    name: "",
    quantity: 0,
    price: 0,
    is_active: "",
  },
};

export const delete_variant = {
  transmit: "single",
  url: "variant_delete",
  request: {
    variant_id: "",
  },
};

export const product_lists = {
  transmit: "single",
  url: "product_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    category_id: "",
    variant_id: "",
    search: "",
  },
};

export const product_kits_lists = {
  transmit: "single",
  url: "product_listing",
  request: {
    filter: "active",
    limit: 5000,
    page: 1,
    category_id: "",
    variant_id: "",
    search: "",
    exclude_from_pack_kit:false
  },
};

export const about_us_lists = {
  transmit: "single",
  url: "story_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const add_about_us = {
  transmit: "single",
  url: "story_add",
  request: {
    title: "",
    description: "",
    images: "",
  },
};

export const edit_about_us = {
  transmit: "single",
  url: "story_update",
  request: {
    story_id: "",
    title: "",
    description: "",
    images: "",
    is_active: "",
  },
};

export const delete_about_us = {
  transmit: "single",
  url: "story_delete",
  request: {
    story_id: "",
  },
};

export const page_lists = {
  transmit: "single",
  url: "page_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const add_page = {
  transmit: "single",
  url: "page_add",
  request: {
    title: "",
    type: "",
    description: "",
  },
};

export const page_detail = {
  transmit: "single",
  url: "page_get",
  request: {
    page_id: "",
  },
};

export const edit_page = {
  transmit: "single",
  url: "page_update",
  request: {
    page_id: "",
    title: "",
    type: "",
    description: "",
    is_active: "",
  },
};

export const delete_product = {
  transmit: "single",
  url: "product_delete",
  request: {
    page_id: "",
  },
};

export const add_product = {
  transmit: "single",
  url: "product_add",
  request: {},
};

export const add_seo = {
  transmit: "single",
  url: "seo_add",
  request: {},
};

export const update_seo = {
  transmit: "single",
  url: "seo_update",
  request: {},
};

export const delete_seo = {
  transmit: "single",
  url: "seo_delete",
  request: {},
};

export const edit_product = {
  transmit: "single",
  url: "product_update",
  request: {},
};
export const product_details = {
  transmit: "single",
  url: "product_get",
  request: {
    product_id: "",
  },
};

export const delete_page = {
  transmit: "single",
  url: "page_delete",
  request: {
    page_id: "",
  },
};

export const kit_lists = {
  transmit: "single",
  url: "kit_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const add_Kit = {
  transmit: "single",
  url: "kit_add",
  request: {
    name: "",
    price: 0,
    product_id: "",
  },
};

export const edit_kit = {
  transmit: "single",
  url: "kit_update",
  request: {
    name: "",
    price: 0,
    product_id: "",
  },
};

export const delete_kit = {
  transmit: "single",
  url: "kit_delete",
  request: {
    kit_id: "",
  },
};

export const edit_retail_store = {
  transmit: "single",
  url: "store_update",
  request: {
    store_id: "",
    city: "",
    state: "",
    postcode: "",
    address: "",
    site_url: "",
    address_url: "",
    is_active: "",
  },
};

export const add_retail_store = {
  transmit: "single",
  url: "store_add",
  request: {
    landmark: "t",
    city: "",
    state: "",
    postcode: "",
    address: "",
    site_url: "",
    address_url: "",
  },
};

export const delete_retail_store = {
  transmit: "single",
  url: "store_delete",
  request: {
    store_id: "",
  },
};

export const product_subscription_lists = {
  transmit: "single",
  url: "productsubscription_listing",
  request: {
    limit: 10,
    page: 1,
    search: "",
  },
};

export const delete_product_subscription_list = {
  transmit: "single",
  url: "productsubscription_delete",
  request: {
    subscription_id: "",
  },
};

export const add_product_subscription_list = {
  transmit: "single",
  url: "productsubscription_add",
  request: {
    name: "",
    discount: 0,
  },
};

export const edit_product_subscription_list = {
  transmit: "single",
  url: "productsubscription_update",
  request: {
    subscription_id: "",
    name: "",
    discount: 0,
  },
};

export const user_lists = {
  transmit: "single",
  url: "user_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const user_details = {
  transmit: "single",
  url: "user_detail",
  request: {
    user_id: "",
  },
};

export const edit_user = {
  transmit: "single",
  url: "user_update",
  request: {
    user_id: "",
    is_active: "",
  },
};

export const community_event_lists = {
  transmit: "single",
  url: "event_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
  },
};

export const delete_community_event = {
  transmit: "single",
  url: "event_delete",
  request: {
    event_id: "",
  },
};

export const add_community_event = {
  transmit: "single",
  url: "event_add",
  request: {
    title: "",
    description: "",
    country: "",
    state: "",
    image: "http://0.0.0.0:7575",
    address: "",
    start_time: "",
    end_time: "",
  },
};

export const edit_community_event = {
  transmit: "single",
  url: "event_update",
  request: {
    event_id: "",
    title: "",
    description: "",
    country: "",
    state: "",
    image: "",
    address: "",
    start_time: "",
    end_time: "",
    is_active: "",
  },
};

export const add_review = {
  transmit: "single",
  url: "review_add",
  request: {
    name: "",
    description: "",
  },
};

export const delete_review = {
  transmit: "single",
  url: "review_delete",
  request: {
    review_id: "",
  },
};

export const edit_review = {
  transmit: "single",
  url: "review_update",
  request: {
    review_id: "",
    name: "",
    description: "",
  },
};

export const review_lists = {
  transmit: "single",
  url: "review_listing",
  request: {
    limit: 10,
    page: 1,
    search: "",
    filter: "all",
  },
};

export const seo_lists = {
  transmit: "single",
  url: "seo_listing",
  request: {
    limit: 10,
    page: 1,
  },
};

export const catering_lists = {
  transmit: "single",
  url: "catering_listing",
  request: {
    limit: 10,
    page: 1,
    search: "",
  },
};

export const delete_catering = {
  transmit: "single",
  url: "catering_delete",
  request: {
    catering_id: "",
  },
};

export const fund_raising_lists = {
  transmit: "single",
  url: "fundraising_listing",
  request: {
    limit: 10,
    page: 1,
    search: "",
  },
};

export const delete_fund_raising = {
  transmit: "single",
  url: "fundraising_delete",
  request: {
    fundraising_id: "",
  },
};

export const order_lists = {
  transmit: "single",
  url: "order_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
    is_export: false,
    file_type:"",
    export_for:"",
    subscription_orders : "all"
  },
};

export const subs_order_lists = {
  transmit: "single",
  url: "order_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
    search: "",
    is_export: false,
    file_type:"",
    export_for:"",
    subscription_orders : "subscription-orders"
  },
};

export const cart_details = {
  transmit: "single",
  url: "order_get",
  request: {
    order_id: "",
  },
};

export const payment_lists = {
  transmit: "single",
  url: "order_payment_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
  },
};

export const add_coupon = {
  transmit: "single",
  url: "coupon_add",
  request: {
    name: "",
    code: "",
    description: "",
    discount_type: "",
    discount_value: 0,
    min_amount: 0,
    usage_limit: 0,
    expiry_date: "",
  },
};

export const coupon_lists = {
  transmit: "single",
  url: "coupon_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
  },
};

export const delete_coupon = {
  transmit: "single",
  url: "coupon_delete",
  request: {
    coupon_id: "",
  },
};

export const edit_coupon = {
  transmit: "single",
  url: "coupon_update",
  request: {
    name: "",
    description: "",
    is_active: "",
  },
};

export const add_gift_card = {
  transmit: "single",
  url: "giftcard_add",
  request: {
    amount: "",
  },
};

export const gift_card_lists = {
  transmit: "single",
  url: "giftcard_listing",
  request: {
    filter: "all",
    limit: 10,
    page: 1,
  },
};

export const delete_gift_card = {
  transmit: "single",
  url: "giftcard_delete",
  request: {
    card_id: "",
  },
};

export const edit_gift_card = {
  transmit: "single",
  url: "giftcard_update",
  request: {
    card_id: "",
    amount: "",
    is_active: "",
  },
};

export const user_giftcard_listing = {
  transmit: "single",
  url: "user_gift_card_listing",
  request: {
    limit: 10,
    page: 1,
  },
};

export const edit_order_status = {
  transmit: "single",
  url: "order_update",
  request: {
    order_id: "",
    status: "",
  },
};

export const setting_order_date={
  transmit: "single",
  url: "setting_order_date_update",
  request: {
   data: {
      excluded_year: "",
      excluded_months: "",
      excluded_days: {
        from: "",
        to: ""
      },
      orders_per_day: ""
    }
  }
}

export const product_previous_sequences= {
  transmit: "single", 
 url:"product_previous_sequences"
}