import moment from "moment";
import { IToggleModalTypes } from "./types";

export function dateFormat(date: string) {
  const formatString = "MMMM Do YYYY, h:mm:ss a";
const formattedDate = moment(date);
  return formattedDate.format(formatString);
}
export function dateFormat2(date: string) {
  const formatString = "MMMM Do YYYY";
const formattedDate = moment(date);
  return formattedDate.format(formatString);
}


export function formatDateTime(date:any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Get the timezone offset in minutes and convert to hours and minutes
  const timezoneOffset = -date.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? "+" : "-";
  const hoursOffset = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');

  // Combine date, time, and timezone offset
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${sign}${hoursOffset}:${minutesOffset}`;
}




export const toggleModal = (state: IToggleModalTypes,setShowModal:any) => {
  setShowModal(state);
};


export function downloadCSV(base64Data:string, fileName:string) {
  // Decode the base64 string
  const decodedData = atob(base64Data);
  
  // Create a Blob from the decoded data
  const blob = new Blob([decodedData], { type: 'text/csv;charset=utf-8;' });

  // Create a link element
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
      // Set the href attribute to a URL created from the Blob
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);

      // Set the download attribute with the desired file name
      link.setAttribute('download', fileName);

      // Trigger the download by programmatically clicking the link
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}


export function downloadXLSX(base64Data:string, fileName:string) {
  // Decode the base64 string
  const decodedData = atob(base64Data);
  
  // Convert the decoded string to an array buffer
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
  }
  
  // Create a Blob from the array buffer
  const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Create a link element
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
      // Set the href attribute to a URL created from the Blob
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);

      // Set the download attribute with the desired file name
      link.setAttribute('download', fileName);

      // Trigger the download by programmatically clicking the link
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}
