import { createReducer } from "@reduxjs/toolkit";
import { Action, ActionTypes } from "../actions/action";
import { InitialState } from "../../app/utils/types";
import { initialShippingdate,initialDashboard, initialFooterSetting, initialOrderDetail, initialPage, initialProductDetail, initialUserDetail } from "../../app/utils/staticdata";

const initialData: InitialState = {
  is_login: "no",
  profiledata: {
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    user_type: "",
    is_active: false,
    id: "",
    created_at: "",
    updated_at: "",
  },
  accessToken: "",
  userId: "",
  deviceInfo: {
    device_id: "",
    device_type: "",
  },
  footer_setting: initialFooterSetting,
  shipping_dates: initialShippingdate,
  category_list: initialPage,
  variant_list: initialPage,
  about_us_list: initialPage,
  pages_list: initialPage,
  product_list: initialPage,
  retail_list: initialPage,
  feedback_list: initialPage,
  product_detail: initialProductDetail,
  kit_list: initialPage,
  subscription_mail_list: initialPage,
  product_subscription_list:initialPage,
  user_list:initialPage,
  user_detail:initialUserDetail,
  community_event_list:initialPage,
  review_list:initialPage,
  seo_list:initialPage,
  catering_list:initialPage,
  fund_raise_list:initialPage,
  order_list:initialPage,
  order_detail:initialOrderDetail,
  payment_list:initialPage,
  coupon_list:initialPage,
  giftcard_list:initialPage,
  dashboard_data:initialDashboard,
  usergift_card_list:initialPage,
  product_list_all: initialPage,
  all_variant_prd_list: initialPage,
  subscription_list:initialPage,
};

const posterReducer = createReducer(initialData, (builder) => {
  builder
    .addCase(ActionTypes.IS_LOGIN, (state, action: Action) => {
      return { ...state, is_login: action.payload };
    })
    .addCase(ActionTypes.GET_PROFILE_USER, (state, action: Action) => {
      return { ...state, profiledata: action.payload };
    })
    .addCase(ActionTypes.ACCESS_TOKEN, (state, action: Action) => {
      return { ...state, accessToken: action.payload };
    })
    .addCase(ActionTypes.DEVICE_INFO, (state, action: Action) => {
      return { ...state, deviceInfo: action.payload };
    })
    .addCase(ActionTypes.USER_ID, (state, action: Action) => {
      return { ...state, userId: action.payload };
    })
    .addCase(ActionTypes.CATEGORY_LIST, (state, action: Action) => {
      return { ...state, category_list: action.payload };
    })
    .addCase(ActionTypes.VARIANT_LIST, (state, action: Action) => {
      return { ...state, variant_list: action.payload };
    })
    .addCase(ActionTypes.ALL_VARIANT_PRODUCTS_LIST, (state, action: Action) => {
      return { ...state, all_variant_prd_list: action.payload };
    })
    .addCase(ActionTypes.ABOUT_US_LIST, (state, action: Action) => {
      return { ...state, about_us_list: action.payload };
    })
    .addCase(ActionTypes.PAGES_LIST, (state, action: Action) => {
      return { ...state, pages_list: action.payload };
    })
    .addCase(ActionTypes.PRODUCT_DETAIL, (state, action: Action) => {
      return { ...state, product_detail: action.payload };
    })
    .addCase(ActionTypes.PRODUCT_LIST, (state, action: Action) => {
      return { ...state, product_list: action.payload };
    })
    .addCase(ActionTypes.ALL_PRODUCT_LIST, (state, action: Action) => {
      return { ...state, product_list_all: action.payload };
    })
    .addCase(ActionTypes.KIT_LIST, (state, action: Action) => {
      return { ...state, kit_list: action.payload };
    })
    .addCase(ActionTypes.RETAIL_STORE_LIST, (state, action: Action) => {
      return { ...state, retail_list: action.payload };
    })
    .addCase(ActionTypes.FEEDBACK_LIST, (state, action: Action) => {
      return { ...state, feedback_list: action.payload };
    })
    .addCase(ActionTypes.SUBSCRIPTION_EMAIL_LIST, (state, action: Action) => {
      return { ...state, subscription_mail_list: action.payload };
    })
    .addCase(ActionTypes.PRODUCT_SUBSCRIPTION_LIST, (state, action: Action) => {
      return { ...state, product_subscription_list: action.payload };
    })
    .addCase(ActionTypes.FOOTER_SETTING, (state, action: Action) => {
      return { ...state, footer_setting: action.payload };
    })
    .addCase(ActionTypes.FOOTER_SETTING_FOR_SHIPPING_DATE, (state, action: Action) => {
      return { ...state, shipping_dates: action.payload };
    })
    .addCase(ActionTypes.USER_LIST, (state, action: Action) => {
      return { ...state, user_list: action.payload };
    })
    .addCase(ActionTypes.USER_DETAIL, (state, action: Action) => {
      return { ...state, user_detail: action.payload };
    })
    .addCase(ActionTypes.COMMUNITY_EVENT_LIST, (state, action: Action) => {
      return {...state, community_event_list: action.payload };
    })
    .addCase(ActionTypes.REVIEW_LIST, (state, action: Action) => {
      return {...state, review_list: action.payload };
    })
    .addCase(ActionTypes.SEO_LIST, (state, action: Action) => {
      return {...state, seo_list: action.payload };
    })
    .addCase(ActionTypes.CATERING_LIST, (state, action: Action) => {
      return {...state, catering_list: action.payload };
    })
    .addCase(ActionTypes.FUND_RAISE_LIST, (state, action: Action) => {
      return {...state, fund_raise_list: action.payload };
    })
    .addCase(ActionTypes.ORDER_LIST, (state, action: Action) => {
      return {...state, order_list: action.payload };
    })
    .addCase(ActionTypes.SUBSCRIPTION_LIST, (state, action: Action) => {
      return {...state, subscription_list: action.payload };
    })

    .addCase(ActionTypes.ORDER_DETAIL, (state, action: Action) => {
      return {...state, order_detail: action.payload };
    })

    .addCase(ActionTypes.PAYMENT_LIST, (state, action: Action) => {
      return {...state, payment_list: action.payload };
    })

    .addCase(ActionTypes.COUPON_LIST, (state, action: Action) => {
      return {...state, coupon_list: action.payload };
    })

    .addCase(ActionTypes.GIFTCARD_LIST, (state, action: Action) => {
      return {...state, giftcard_list: action.payload };
    })

    .addCase(ActionTypes.USERGIFT_CARD_LIST, (state, action: Action) => {
      return {...state, usergift_card_list: action.payload };
    })

    .addCase(ActionTypes.DASHBOARD_DETAIL, (state, action: Action) => {
      return {...state, dashboard_data: action.payload };
    })

    .addCase(ActionTypes.ADMIN_LOGOUT, (state) => {
      return initialData; // Reset state to initialData on admin logout
    });
});

export default posterReducer;
