import React, { useEffect, useState } from "react";
import { LuEye, LuSearch } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { useLocation } from 'react-router-dom';
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import { product_previous_sequences } from "../../../api/websocket/payload";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";

interface InputFieldTypes {
  type?: string;
  name: string;
  placeholder: string;
  inputClassName?: string;
  className?: string;
  useFor?: string;
  register?: any;
  error?: any;
  key?: any;
  lableName?: any;
  required?: boolean;
  onEnterClick?: Function
  onChange?: Function
  isInfo?:boolean
}

interface CategoryData {
  id: string;
  category_name: string;
  sequence_numbers: number[];
}

interface FormattedData {
  category: string;
  sortedSequenceNumbers: number[];
}

const InputField = (prop: InputFieldTypes) => {
  const [Focus, setFocus] = useState(false);
  const [Password, setPassword] = useState(false);
  const location = useLocation();
  const isShippingDateValidationRoute = location.pathname.includes("setting/shipping-date-validation");
  const { send, receivedMessage, isConnect } = useWebSocket();
  const [data,setData] = useState([])

  useEffect(() => {
    if (isConnect && prop?.isInfo) {
      const message = product_previous_sequences;
      send(message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnect, receivedMessage?.url,prop?.isInfo]);
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === product_previous_sequences.url) {
          if (receivedMessage?.response?.status === 200) {
          setData(receivedMessage?.response.data)
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedMessage]);

  const sortSequenceNumbers = (data: CategoryData[]): CategoryData[] => {
    return data?.map((category) => ({
        ...category,
        sequence_numbers: category?.sequence_numbers?.sort((a, b) => a - b),
    }));
};

const sortedData = sortSequenceNumbers(data);





  return (
    <div className={`${prop.className}`}>
      {prop.useFor !== "search" && (
        <p className={`font-medium text-gray-600 text-sm mb-[1px] ${prop?.isInfo? 'flex':""}`}>
          {prop.name}{prop.required&&<span className="text-red-500"> *</span>}
          {prop?.isInfo&&(
           <div className="relative group flex items-center ml-1">
           <svg xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 text-blue-500 cursor-pointer" 
                fill="none" viewBox="0 0 24 24" 
                stroke="currentColor" stroke-width="2">
             <path stroke-linecap="round" stroke-linejoin="round" 
                   d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 100 20 10 10 0 000-20z" />
           </svg>
           <div className="lg:w-[300px] h-auto absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 
                       hidden group-hover:flex items-center bg-gray-800 text-white 
                       text-sm font-medium px-3 py-1 rounded shadow-lg">
             <div className="flex flex-col">
              <p className="font-medium text-sm text-center mb-2 mt-1">Previously used sequence numbers </p>
             {sortedData?.map(({ id, category_name, sequence_numbers }) => (
                <div key={id} className="flex flex-row w-full mb-[3px]">
                    <span className="font-medium text-sm">{category_name}: {sequence_numbers.join(', ')} </span>
                </div>
            ))}
             </div>
           </div>
         </div>
          )}
        </p>
      )}
      {prop.useFor !== "textarea" ? (
        <div>
          <div
            className={`flex border-[1px] rounded  ${prop.inputClassName} ${
              Focus ? "border-[#2737FF]" : "border-gray-200"
            }`}
          >
            {prop.useFor === "search" && <LuSearch className="h-full ml-2" />}
            <input
            onKeyDown={prop.onEnterClick&&prop.onEnterClick}
              placeholder={prop.placeholder}
              type={prop.type === "password" && !Password ? "password" : "text"}
              className={`bg-white rounded focus:outline-none w-full px-2 placeholder:text-sm text-sm  h-auto ${prop.inputClassName}`}
              {...prop.register}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
             
            />
            {prop.type === "password" &&
              (Password ? (
                <LuEyeOff
                  className={`cursor-pointer h-full mr-2`}
                  onClick={() => setPassword(!Password)}
                />
              ) : (
                <LuEye
                  className={`cursor-pointer h-full mr-2`}
                  onClick={() => setPassword(!Password)}
                />
              ))}
          </div>
          {(prop.error && prop.lableName === "Price_select") && (<div className="h-4">
              <p className="text-xs  mx-1  text-red-500">
              price is required
              </p>
          </div>)}
          <div className={`${isShippingDateValidationRoute ? 'h-auto' : 'h-4'} error_msg`}>
            {prop.error && (
              <p className="text-xs  mx-1  text-red-500">
                {prop.error.message}
              </p>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            className={`bg-white  border-[1px]  rounded  w-full px-2 py-2
          ${prop.inputClassName}`}
          >
            {/* ${Focus ? "border-[#2737FF]" : "border-gray-200"}   */}
            <textarea
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              name=""
              id=""
              {...prop.register}
              placeholder={prop.placeholder}
              className="rounded focus:outline-none w-full text-sm resize-none placeholder:text-sm"
            />
          </div>
          <div className="h-4 error_msg">
            {prop.error && (
              <p className="text-xs  mx-1  text-red-500">
                {prop.error.message}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InputField;
