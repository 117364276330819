import { useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { RiExpandLeftLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setLogout } from "../../../redux/actions/action";
import {
  breadcrumbNameMap,
  logoutContaint,
  noComponentPaths,
} from "../../utils/staticdata";
import { getData } from "../../api/rest/fetchData";
import { LOG_OUT } from "../../constant/url";
import { usePosterReducers } from "../../../redux/getdata/usePostReducer";
import { toast } from "react-toastify";
import WarnPopup from "./popup/WarnPopup";
import { IToggleWarnModalTypes } from "../../utils/types";
import { TbPlugConnected } from "react-icons/tb";
import { useUiReducer } from "../../../redux/getdata/useUiReducer";
import { TbPlugConnectedX } from "react-icons/tb";
interface IHeaderTypes {
  // Define any additional props if needed
}

const sidebarToggle = (e: any) => {
  e.preventDefault();
  document.getElementById("panel-sidebar")?.classList.remove("hidden");
};

const Header = (props: IHeaderTypes) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const heading=pathname.split('/')[pathname.split('/').length-1]
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { accessToken } = usePosterReducers();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowWarn("hide");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    getData(LOG_OUT, accessToken).then((res) => {
      if (res.status === 200) {

        toast.success(res?.data?.msg);
        dispatch(setLogout());
        navigate("/login");
      }
      if (res.status === 400) {
        toast.error(res?.msg);
      }

      setShowWarn("hide");
    });
  };

  function toggleModal(e: any, item: any, type: string) {
    if (type === "delete") setShowWarn("delete");
    if (type === "hide") setShowWarn("hide");
  }

  // Extract segments from pathname for breadcrumbs
  const pathSegments = pathname.split("/").filter(Boolean);

  // Filter out segments that match a dynamic ID pattern
  const filteredSegments = pathSegments.filter(
    (segment) =>
      !segment.match(
        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i
      )
  );

  const { isSocketConnected } = useUiReducer();
  // Construct breadcrumb items
  const breadcrumbs = filteredSegments?.map((segment, index) => {
    const path = `/${filteredSegments?.slice(0, index + 1).join("/")}`;
    const displayName = breadcrumbNameMap[segment] || segment;
    const isDisabled = noComponentPaths.includes(segment);
    return isDisabled ? (
      <span
        key={index}
        className={`text-gray-500 capitalize ${
          index < filteredSegments.length - 1
            ? "after:content-['/'] after:mx-2"
            : ""
        }`}
      >
        {displayName}
      </span>
    ) : (
      <Link
        key={index}
        to={path}
        className={`text-gray-500 hover:text-primary capitalize ${
          index < filteredSegments.length - 1
            ? "after:content-['/'] after:mx-2"
            : ""
        }`}
      >
        {displayName}
      </Link>
    );
  });

  return (
    <div className="flex justify-between items-center p-2 m-2 px-4 rounded-md bg-white">
      <div className="flex items-center text-2xl gap-2">
        <HiMenuAlt3 onClick={(e) => sidebarToggle(e)} className="lg:hidden" />
        <div className="hidden lg:flex items-center  text-sm space-x-2">{breadcrumbs}</div>
        <div className=" lg:hidden text-sm space-x-2 text-gray-500">{heading.charAt(0).toUpperCase()+heading.slice(1)}</div>
      </div>
      <div className="flex justify-center items-center gap-4 ">
        {isSocketConnected ? (
          <div className="flex shadow-sm justify-center items-center text-sm bg-green-500 px-2 gap-1 rounded-md py-1 lg:py-[0.02] text-white">
            <TbPlugConnected />
            {/* <p className="hidden lg:block">onnected</p> */}
          </div>
        ) : (
          <div className="flex shadow-sm justify-center  items-center text-sm bg-red-500 px-2 gap-1 rounded-md py-1 lg:py-[0.02] text-white">
            <TbPlugConnectedX />
            {/* <p className="hidden lg:block">Disconnected</p> */}
          </div>
        )}
        <div
          className="hover:text-primary text-gray-500 cursor-pointer hover:bg-white text-sm rounded-md flex items-center gap-2"
          onClick={(e) => toggleModal(e, "", "delete")}
        >
          <RiExpandLeftLine />
          <p>Logout</p>
        </div>
      </div>
      <WarnPopup
        toggleModal={toggleModal}
        containt={logoutContaint}
        onSubmit={handleLogout}
        showModal={showWarn}
      ></WarnPopup>
    </div>
  );
};

export default Header;

